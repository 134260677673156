import React from "react";
import {Flex, Text} from "@chakra-ui/react";
import { useSelector } from "react-redux";

import './gradTable.css'


function AlumniTable() {
  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
      <>
      {isMobile ? 
      (<>
          <Flex  left="150px" position= 'left' >
            <Text fontSize="30pt" fontFamily="Times New Roman" fontWeight="bold" color="red" textDecorationLine="underline"> Previous Members </Text>
          </Flex>
          <Flex  left="150px" position= 'left'>
            <Text fontSize="16.5pt" fontFamily="Times New Roman" fontWeight="bold" textDecorationLine="underline"> Graduate Students (Ph.D and Master's in chronological order) </Text>
          </Flex>
      </>) : 
      (<>
          <Flex  left="150px" position= 'relative' >
            <Text fontSize="30pt" fontFamily="Times New Roman" fontWeight="bold" color="red" textDecorationLine="underline"> Previous Members </Text>
          </Flex>
          <Flex  left="150px" position= 'relative'>
            <Text fontSize="16.5pt" fontFamily="Times New Roman" fontWeight="bold" textDecorationLine="underline"> Graduate Students (Ph.D and Master's in chronological order) </Text>
          </Flex>
      </>)}


      
      <div className="tableContainer">
      {/* First Table */}
      <table className="gradTable">
        <thead>
          <tr>
            <th>Ph.D. Students</th>
          </tr>
        </thead>
        <tbody>     
          <tr>
            <td>
              <b>Ahmed Omara</b>, University of Ottawa, Ph.D. in Electrical and Computer Engineering awarded in October 2024.<br />
              <u>Current Position and Affiliation:</u> <i>Department of National Defense'te Machine Learning Engineer</i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Bin Xiao</b>, University of Ottawa, Ph.D. in Computer Science awarded in September 2024.<br />
              <u>Current Position and Affiliation:</u> <i></i>
            </td>
          </tr>

          <tr>
            <td>
              <b>Zhiyan Chen</b>, University of Ottawa, Ph.D. in Electrical and Computer Engineering awarded in June 2023.<br />
              <u>Current Position and Affiliation:</u> <i>Fortinet; machine learning security engineer</i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Liu Jinxin</b>, University of Ottawa, Ph.D. in Electrical and Computer Engineering awarded in June 2023.<br />
              <u>Current Position and Affiliation:</u> <i>Huawei</i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Andressa Vergütz</b>, University of Ottawa, Ph.D. in Computer Science awarded in June 2022.<br />
              <u>Current Position and Affiliation:</u> <i>Haijunka 360 co-founder</i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Safa Otoom</b>, University of Ottawa, Ph.D. in Electrical and Computer Engineering awarded in June 2019.<br />
              <u>Current Position and Affiliation:</u> <i>Assistant Professor, Zayed University-Abu Dhabi</i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Maryam Pouryazdanpanah</b>, Clarkson University, Ph.D in ECE awarded in August 2018.<br />
              <u>Current Position and Affiliation:</u> <i>IoT-Data Scientist, Watts Water Technologies (Boston, MA)</i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Fazel Anjomshoa</b>, Clarkson University, Ph.D in ECE awarded in May 2018<br />
              <u>Current Position and Affiliation:</u> <i>Data Scientist at Roku Inc., (Boston ,MA)</i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Claudio Fiandrino</b>, University of Luxembourg (visiting phd student), PhD in Computer Science awarded in November 2017<br />
              <u>Current Position and Affiliation:</u> <i>Postdoctoral researcher, Imdea Networks (Madrid, Spain)</i>
            </td>
          </tr>
          <tr>
            <td>
              <b> Riccardo Venanzi</b>, (Visiting PhD student from University of Ferrara, Italy)<br />
              <u>Current Position and Affiliation:</u> <i>Ph.D candidate in Computer Science, University of Ferrara, Italy</i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Dimitri Belli</b>, (Visiting PhD student from University of Pisa, Italy)<br />
              <u>Current Position and Affiliation:</u> <i>Ph.D candidate in Computer Science, University of Pisa, Italy</i>
            </td>
          </tr>
          {/* More rows as needed */}
        </tbody>
      </table>

      {/* Second Table */}
      <table className="gradTable">
        <thead>
          <tr>
            <th>Master's Students</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Claire Zhang </b>, University of Ottawa, Master in System Science awarded in 2024. <br />
              <u>Current Position and Affiliation:</u> <i> Research Trainee, European Space Agency </i>
              </td>
          </tr>
          <tr>
            <td>
              <b>Murat Arda Onsu </b>, University of Ottawa, Master in Computer Science awarded in 2023. <br />
              <u>Current Position and Affiliation:</u> <i>  Ph.D student, University of Ottawa </i>
              </td>
          </tr>
          <tr>
            <td>
              <b>Mohsen Shahbazi </b>, University of Ottawa, Master in Electrical and Computer Engineering awarded in 2023. <br />
              <u>Current Position and Affiliation:</u> <i> Cisco </i>
              </td>
          </tr>
          <tr>
            <td>
              <b> Nahid Parvaresh </b>, University of Ottawa, Master in Computer Science awarded in 2023 <br />
              <u>Current Position and Affiliation:</u> <i> Advanced Symbolics </i>
              </td>
          </tr>
          <tr>
            <td>
              <b> Yu Shen </b>, University of Ottawa, Master in Electrical and Computer Engineering awarded in 2022. <br />
              <u>Current Position and Affiliation:</u> <i> Nokia </i>
              </td>
          </tr>
          <tr>
            <td>
              <b> Johan Fernandes </b>, University of Ottawa, Master in Computer Science awarded in 2022. <br />
              <u>Current Position and Affiliation:</u> <i> Statistics Canada </i>
              </td>
          </tr>
          <tr>
            <td>
              <b> Yakup Akkaya </b>, University of Ottawa, Master in Electrical and Computer Engineering awarded in 2022. <br />
              <u>Current Position and Affiliation:</u> <i> J.D. Power </i>
              </td>
          </tr>
          <tr>
            <td>
              <b> Cem Mumtaz Eris </b>, Master in Computer Science awarded in 2022. <br />
              <u>Current Position and Affiliation:</u> <i> Entrapeer</i>
              </td>
          </tr>
          <tr>
            <td>
              <b>Sedevizo Kielienyu </b>, (Master of Computer Science, uOttawa, August 2020)<br />
              <u>Current Position and Affiliation:</u> <i>Data Scientist, Coastal Resource Mapping (CRM), Vancouver, BC </i>
              </td>
          </tr>
          <tr>
            <td>
              <b>Ji Chu Jiang </b>,(Master of Applied Science, Electrical and Computer Engineering, uOttawa, December 2020) <br />
              <u>Current Position and Affiliation:</u> <i>Current Position: Software Engineer at Lytica Inc., Ottawa, ON</i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Kyle Quintal </b>, (Master of Computer Science, University of Ottawa, August 2020)<br />
              <u>Current Position and Affiliation:</u> <i> Data Scientist, AedoAI (Calgary, AB) </i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Yueqian Zhang </b>, (Master of Computer Science, University of Ottawa, Dec 2019)<br />
              <u>Current Position and Affiliation:</u> <i> Recent graduate </i>
            </td>
          </tr>
          <tr>
            <td>
              <b> Ahmed Omara </b>,(Master of Applied Science in Electrical and Computer Engineering, University of Ottawa, Dec 2019) <br />
              <u>Current Position and Affiliation:</u> <i>Ph.D student, University of Ottawa </i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Ertugrul Kara </b>, (Master of Computer Science, University of Ottawa, July 2019)<br />
              <u>Current Position and Affiliation:</u> <i>Software Engineer, Amazon (Vancouver, BC) </i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Venkat Surya Dasari  </b>, (Master of Applied Science in Electrical and Computer Engineering, University of Ottawa, July 2019) <br />
              <u>Current Position and Affiliation:</u> <i> Researcher </i>
            </td>
          </tr>
          <tr>
            <td>
              <b> Ankkita Sood </b>, (Master of Computer Science, University of Ottawa, June 2019) <br />
              <u>Current Position and Affiliation:</u> <i>Data analyst, Kinaxis (Ottawa, ON) </i>
            </td>
          </tr>
          <tr>
            <td>
              <b> Cem Kaptan </b>, (Master of Computer Science, University of Ottawa, August 2018) <br />
              <u>Current Position and Affiliation:</u> <i>Software Developer, Wirkn (Montréal, QC) (also continuing Ph.D at NEXTCON) </i>
            </td>
          </tr>
          <tr>
            <td>
              <b>Zachary I. Rauen </b>, (M.Sc in Electrical Engineering, Clarkson University, May 2018) <br />
              <u>Current Position and Affiliation:</u> <i>Firmware/Security Team member, IBM (Schenectady, NY) </i>
            </td>
          </tr>
          <tr>
            <td>
              <b> Wendong Yuan</b>, (Master of Computer Science, University of Ottawa, June 2018) <br />
              <u>Current Position and Affiliation:</u> <i>Software Engineer at Fortinet Inc. (Ottawa, ON) </i>
            </td>
          </tr>
          <tr>
            <td>
              <b>George Patrick Xavier </b>,(Master of Computer Science, University of Ottawa, June 2018) <br />
              <u>Current Position and Affiliation:</u> <i> Digital Integration Solutions Developer at Deloitte Canada (Montréal, QC) </i>
            </td>
          </tr>
          <tr>
            <td>
              <b> Damanpreet Singh  </b>, (Master of Computer Science, University of Ottawa, June 2018) <br />
              <u>Current Position and Affiliation:</u> <i> Current Position and Affiliation: Software Development Engineer, IBM  (Ottawa, ON) </i>
            </td>
          </tr>
          {/* More rows as needed */}
        </tbody>
      </table>
    </div>
    </>
  );
}
  


export default AlumniTable;