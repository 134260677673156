import React from "react";
import {Text, Image, Link} from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";


import './mediaTable.css'

function media() {
    return (
        <>
        <div className="tableContainer">
        {/* First Table */}
        <table className="mediaTable">
          <thead>
            <tr>
              <th>Source</th>
              <th>Link-Date</th>
              <th>HeadLine</th>
            </tr>
          </thead>
          <tbody>
            {/* More rows Beginning */}
            <tr>
              <td>
              <br/><Image src="med2.jpg" alt="med2" className={css(styles.images)} />
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/>How uOttawa empowers local startup success through R&D collaborations  <br/> 3 December 2024
                </Text>
              </td>
              <td>
              <Link href="https://obj.ca/how-uottawa-empowers-local-startup-success-through-rd-collaborations/" className={css(styles.Newslink)} target="_blank">
              <br/>https://obj.ca/how-uottawa-empowers-local-startup-success-through-rd-collaborations/
                </Link>
              </td>
            </tr>
            {/* More rows as Ending */}
            {/* More rows Beginning */}
            <tr>
              <td>
              <br/><Image src="media_2.jpg" alt="media_2" className={css(styles.images)} />
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/>Kanata Networker, Multi-access edge computing accelerated in Kanata North  <br/><br/> 30 June 2024
                </Text>
              </td>
              <td>
              <Link href="https://www.kanatanorthba.com/multiaccess-edge-computing-kanata-north/" className={css(styles.Newslink)} target="_blank">
              <br/>https://www.kanatanorthba.com/multiaccess-edge-computing-kanata-north/
                </Link>
              </td>
            </tr>
            <tr>
              <td>
              <br/><Image src="media_1.jpg" alt="media_1" className={css(styles.images)} />
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/>Smart Connected Vehicles Innovation Centre was featured in Ottawa Business Journal <br/><br/> 1 April 2024
                </Text>
              </td>
              <td>
              <Link href="https://obj.ca/uottawas-kanata-north-campus-marks-5-years-in-tech-park/" className={css(styles.Newslink)} target="_blank">
              <br/>https://obj.ca/uottawas-kanata-north-campus-marks-5-years-in-tech-park/
                </Link>
              </td>
            </tr>
            {/* More rows as Ending */}
            <tr>
              <td>
              <br/><Image src="Nextcon-Media1.gif" alt="Nextcon-Media1.gif" className={css(styles.images)} />
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/>Things Happen Podcast on Lybsin, <br/><br/> 22 February 2022
                </Text>
              </td>
              <td>
              <Link href="https://directory.libsyn.com/episode/index/show/iotnorth/id/22186334" className={css(styles.Newslink)} target="_blank">
              <br/>Things Happen Podcast Series. Episode 14: Innovating Smart Connected Vehicles and Cities
                </Link>
              </td>
            </tr>
            {/* More rows Beginning */}
            <tr>
              <td>
              <br/><Image src="med1.jpg" alt="med1.jpg" className={css(styles.images)} />
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/><br/> uOttawa - Gazette, le 29 octobre 2021
                </Text>
              </td>
              <td>
              <Link href="https://www.uottawa.ca/fr/nouvelles-gazette" className={css(styles.Newslink)} target="_blank">
              <br/> Drones, robots, voitures : le nouveau centre d’innovation de Kanata-Nord propulsera l’avenir des véhicules autonomes
                </Link>
              </td>
            </tr>
            {/* More rows as Ending */}
            {/* rows Beginning */}
             <tr>
              <td>
              <br/><Image src="med1.jpg" alt="med1" className={css(styles.images)} />
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/><br/>  uOttawa - Gazette, 29 October 2021 
                </Text>
              </td>
              <td>
              <Link href="https://www.uottawa.ca/en/gazette-news" className={css(styles.Newslink)} target="_blank">
              <br/> Drones, bots and self-driving cars: How this new Kanata North innovation centre will help drive the future of autonomous vehicles
                </Link>
              </td>
            </tr>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              <br/><Image src="med2.jpg" alt="med2" className={css(styles.images)} />
              </td>
              <td>
              <Link href="https://obj.ca/article/Faculty-engineering-sponsored-creating-water-cooler-moments-drive-connected-car-innovation-kanata-north" className={css(styles.Newslink)} target="_blank">
              <br/> Ottawa Business Journal, 27 Sep. 2021
                </Link>
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/> Creating ‘water cooler moments’ to drive connected car innovation in Kanata North
                </Text>
              </td>
            </tr>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              <br/><Image src="med2.jpg" alt="med2" className={css(styles.images)} />
              </td>
              <td>
              <Link href="https://obj.ca/article/faculty-of-engineering-sponsored-uottawa-kanata-north-ramping-smart-connected-vehicles-innovation-lab" className={css(styles.Newslink)} target="_blank">
              <br/> Ottawa Business Journal, 18 Dec 2019
                </Link>
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/> uOttawa Kanata North ramping up Smart Connected Vehicles Innovation Lab
                </Text>
              </td>
            </tr>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              <br/><Image src="med3.jpg" alt="med3" className={css(styles.images)} />
              </td>
              <td>
              <Image src="med7.jpg" alt="med7" className={css(styles.images)}/>
              <Link href="https://www.youtube.com/watch?v=YipJrqZea5E&t=3256s" className={css(styles.Newslink)} target="_blank">
              Click Here for the video
                </Link>
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/><br/> CAV Canada Panel: How CAVs will impact society
                </Text>
              </td>
            </tr>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              <br/><br/><br/><Image src="med2.jpg" alt="med2" className={css(styles.images)} />
              </td>
              <td>
              <Link href="https://obj.ca/article/uottawa-engineering-search-safer-cities-university-ottawa-researchers-eye-new-uses-big-data-and-vehicular-crowd" className={css(styles.Newslink)} target="_blank">
              <br/><br/><br/> Ottawa Business Journal, 24 June 2019
                </Link>
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/><br/> In search of safer cities, University of Ottawa researchers eye new uses for big data and vehicular crowd-sensing
                </Text>
              </td>
            </tr>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              <br/><Image src="med4.gif" alt="med4" className={css(styles.images)} marginLeft="120px" />
              </td>
              <td>
              <Link href="https://twitter.com/TheOfficialACM/status/1083418240699772929?s=20" className={css(styles.Newslink)} target="_blank">
              <br/><br/> ACM DSP Announcement
                </Link>
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/><br/> ACM Distinguished Speaker Dr. Burak Kantarci
                </Text>
              </td>
            </tr>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              
              </td>
              <td>
              <Link href="https://www.youtube.com/watch?v=52b8uznYf4o" className={css(styles.Newslink)} target="_blank">
              TAF Network, -
                </Link>
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                 Interview with TAFNetwork (In Turkish)
                </Text>
              </td>
            </tr>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              <br/><Image src="med5.jpg" alt="med5" className={css(styles.images)} />
              </td>
              <td>
              <Link href="https://nextconlab.ca/mpcourier-bestpaper.pdf.pdf" className={css(styles.Newslink)} target="_blank">
              <br/><br/> Daily Courier Observer, 03/11/2016
                </Link>
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/><br/> CU Researchers Write "Best Paper"
                </Text>
              </td>
            </tr>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              <br/><Image src="med6.jpg" alt="med6" className={css(styles.images)} marginLeft="90px"/>
              </td>
              <td>
              <Link href="https://www.clarkson.edu/news/2015/news-release_2015-08-31-1.html" className={css(styles.Newslink)} target="_blank">
              <br/><br/> Clarkson News Release, 08/31/2015
                </Link>
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/><br/> CU Receives Support from Qualcommy
                </Text>
              </td>
            </tr>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              <br/><Image src="med5.jpg" alt="med5" className={css(styles.images)} />
              </td>
              <td>
              <Link href="https://nextconlab.ca/DCO08042015.pdf" className={css(styles.Newslink)} target="_blank">
              <br/><br/> Daily Courier Observer, 08/04/2015
                </Link>
              </td>
              <td>
                <Text className={css(styles.Newscontent)}>
                <br/><br/> CU's Kantarci Elected Committee Secretary
                </Text>
              </td>
            </tr>
            {/* rows as Ending */}
          </tbody>
        </table>

      </div>
    </>
    );
  }
  
  const styles = StyleSheet.create({
    images: {
        fit: "fit",
    },
    Newslink: {
        textDecoration: 'underline',
        width: '100%',
        color:"darkblue",
        fontWeight:"bold"
    },
    Newscontent: {
        width: '100%',
        fontWeight:"bold",
    },
})


  export default media;