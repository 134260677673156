import React from 'react';
import { Flex, Box, Image, Text, Icon, Link, SimpleGrid } from '@chakra-ui/react';
import { BsLinkedin } from 'react-icons/bs'; // Example icon, you can choose any
import { StyleSheet, css } from 'aphrodite';



function Footer() {
    return (
        <>
            <Flex className={css(styles.parent)} align="left" justify="space-between">
                <Flex align="left" className={css(styles.container2)}>
                    <Image src="nextcon_full.png" alt="nserc logo" width="420px" />
                </Flex>
                <Flex align="right" className={css(styles.container2)}>
                    <Image
                        src="NEXTCON-BurakKantarci_Profile.jpg" // Path to the director's image
                        alt="Director"
                        boxSize="150px"
                        mr={3}
                    />
                    <Box align="center">
                    <SimpleGrid columns={2} spacing={0} textAlign="left"  >
                        <Box justifyContent="left" alignContent="left">
                            <Text fontSize="m" fontWeight="bold">For more information, contact:</Text>
                            <Text fontSize="m" fontStyle="italic">Dr. Burak Kantarci, </Text>
                            <Text fontSize="m" > Professor & University Research Chair in AI-Enabled <br/> Secure Networking for Smart Critical Infrastructures <br/> <br/> School of Electrical Engineering and Computer Science <br/> Faculty of Engineering <br/> University of Ottawa </Text>
                        </Box>
                        <Box justifyContent="left" alignContent="left">
                            <Link href="scvic-civic@uottawa.ca" color="blue.500" isExternal>
                            scvic-civic@uottawa.ca:
                            </Link>
                            <br/>
                            <Link href="nextconlab.ca/" color="blue.500" isExternal>
                                https://nextconlab.ca/:
                            </Link>
                            <br/>
                            <Link href="https://www.linkedin.com/company/smart-connected-vehicles-innovation-centre/about/" color="blue.500" isExternal>
                                Follow us on LinkedIn:
                                <Icon as={BsLinkedin} w={6} h={6} color="blue.500" />
                            </Link>
                        </Box>
                    </SimpleGrid>
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};

const styles = StyleSheet.create({
    parent: {
        backgroundColor: '#d3d3d3',
        marginTop:'80px',
        padding: '1%',
        width: '100%',
        display: 'flex',
        bottom: 0, // Sticks the footer to the bottom of the viewport
    },
    container1: {
        justifyContent: 'center',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
    },
    container2: {
        justifyContent: 'center',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
    },
    links: {
        fontWeight: 'bold',
        ":hover": {
            textDecoration: 'underline',
            color: '#DC242C'
        }
    }
})

export default Footer;